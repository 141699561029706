import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const Privacy = () => {
  const data = useStaticQuery(query)
  const { privacyPolicy } = data.iubendaDocument

  return (
    <Layout>
      <Seo title="Privacy" />
      <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="relative w-full px-6 py-12 prose prose-slate lg:prose-lg prose-sky bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28">
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
const query = graphql`
{
  iubendaDocument {
    privacyPolicy
  }
}
`